import React from 'react'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import ChairAccessories from '../components/ChairAccessories'

const AccessoriesPage = () => (
  <Layout>
    <SEO
      title="Nail Sallon Accessories"
      description="Made with durable and acetone resistant material, Contego Spa Designs Accessories, Mani Stool, Pedi Stool, Tech Chair, and Customer Chair will satisfy you. You will not worry about spilling any more."
      meta={[
        {
          name: 'keywords',
          content:
            'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
        }
      ]}
    />
    <div className="container-fluid py-4 px-0 manistool-bg">
      <ChairAccessories
        model="manistool"
        name="Mani Stool"
        des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
        defaultChairColor="burgundy"
        defaultTexture="regular"
        price="185"
      />
    </div>
    <div className="container-fluid py-4 px-0 pedistool-bg">
      <ChairAccessories
        model="pedistool"
        name="Pedi Stool"
        des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
        defaultChairColor="fossilgrey"
        defaultTexture="diamond"
        price="185"
      />
    </div>
    <div className="container-fluid py-4 px-0 techchair-bg">
      <ChairAccessories
        model="techchair"
        name="Tech Chair"
        des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
        defaultChairColor="cappuccino"
        defaultTexture="diamond"
        price="195"
      />
    </div>
    <div className="container-fluid py-4 px-0 customerchair-bg">
      <ChairAccessories
        model="customerchair"
        name="Customer Chair"
        des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
        defaultChairColor="ivory"
        defaultTexture="regular"
        price="295"
      />
    </div>
  </Layout>
)

export default AccessoriesPage
